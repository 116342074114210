import React, { useState } from "react"
import Topbar from "../../../components/organisms/topbar"
import CustomLayout from "../../../Layouts/Layout"
import RegConfirm from "./RegConfirm"
import Register from "./register-form"

const RegisterCompany = () => {
  const [isGoToConfirmPage, setGoToConfirmPage] = useState(false)
  const [dataInput, setDataInput] = useState()
  return (
    <div>
      <Topbar />
      <CustomLayout
        selectedPage={
          !isGoToConfirmPage ? (
            <Register
              dataInput={dataInput}
              setGoToConfirmPage={setGoToConfirmPage}
              setDataInput={setDataInput}
            />
          ) : (
            <RegConfirm
              dataInput={dataInput}
              setGoToConfirmPage={setGoToConfirmPage}
            />
          )
        }
      />
    </div>
  )
}

export default RegisterCompany
