import { Button, Col, Divider, Row, Space } from "antd"
import { Content } from "antd/lib/layout/layout"
import Title from "antd/lib/typography/Title"
import { Link } from "gatsby"
import React from "react"

const RegConfirm = ({ dataInput, setGoToConfirmPage }) => {
  console.log(dataInput)
  const processedData = {
    ...dataInput,
    DateofEstablishment: `${dataInput?.DateofEstablishment?.Year}/${dataInput?.DateofEstablishment?.Month}`,
    RepresentativeName: `${dataInput?.RepresentativeName?.FirstName} ${dataInput?.RepresentativeName?.LastName}`,
    RepresentativeName2: `${dataInput?.RepresentativeName2?.FirstName} ${dataInput?.RepresentativeName2?.LastName}`,
    電話番号: `${dataInput?.phoneNumber?.prefix}-${dataInput?.phoneNumber?.middleNumber}-${dataInput?.phoneNumber?.lastNumber}`,
    FAX番号: `${dataInput?.Fax?.prefix}-${dataInput?.Fax?.middleNumber}-${dataInput?.Fax?.lastNumber}`,
    shipping: [
      `${dataInput?.shipping[0]?.province} ${dataInput?.shipping[0]?.street} ${dataInput?.shipping[0]?.text}`,
      `${dataInput?.shipping[1]?.province} ${dataInput?.shipping[1]?.street} ${dataInput?.shipping[1]?.text}`,
      `${dataInput?.shipping[2]?.province} ${dataInput?.shipping[2]?.street} ${dataInput?.shipping[2]?.text}`,
    ],
  }
  const output = Object?.entries(processedData).map(([label, data]) => ({
    label,
    data,
  }))
  console.log(output)
  return (
    <div>
      <Content>
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <Title
            level={3}
            style={{
              marginBottom: "1rem",
              padding: "20px 25px",
            }}
          >
            なめたろう株式会社
          </Title>
          {output?.map((item, i) => {
            return (
              (typeof item.data === "string" || item.label === "shipping") &&
              !item.data.includes("undefined") && (
                <Row>
                  <Col span={6} style={{ color: "#888888" }}>
                    {item.label}
                  </Col>
                  <Col span={16} style={{ fontWeight: "bold" }}>
                    {item.label === "shipping"
                      ? item.data.map((item) => {
                          return <div>{item}</div>
                        })
                      : item.data}
                  </Col>
                  <Divider plain></Divider>
                </Row>
              )
            )
          })}
          <Row>
            <Space>
              <Button
                onClick={() => {
                  setGoToConfirmPage(false)
                }}
                className="flex justify-center"
              >
                変更に戻る
              </Button>
              <Link to={"./thank-you"}>
                <Button type="primary">登録する</Button>
              </Link>
            </Space>
          </Row>
        </div>
      </Content>
    </div>
  )
}

export default RegConfirm
